
<template>
  <div :data-updatekey="spec.projectId" class="flex justify-start">
    <div class="bg-white shadow sm:rounded-md" style="display: flex;">
      <div id="hill_chart_container">
        <h3 v-if="heading" id="hill-chart-heading" class="pt-3 text-lg font-medium text-gray-600 text-center mb-2">
          {{heading}}
        </h3>

        <!-- <epics-hillChartLocate
          style="margin-top: -24px; margin-left: 750px;"
          v-if="dataPoints.length > 1"
          :hillChart="dataPoints"
          :broughtToFrontKey="broughtToFrontKey" /> -->

        <svg class="hill-chart" :class="readOnly ? null : 'editable'"></svg>
      </div>

      <div class="action-container">
        <div v-if="isDirty" class="dirty-icon">
          <glib-component
            :spec="dirtyIconSpec"
          />
          <!-- <glib-icon :spec="dirtyIconSpec" /> -->
        </div>

        <glib-component
          v-if="dataPoints.length > 1"
          :spec="locateButton()"
        />
      </div>
    </div>

     <!-- style="display: flex;" -->

    <!-- <div class="flex justify-between items-center mt-3 px-4 py-2 bg-gray-50 sm:px-6"> -->
    <div>
      <slot />

      <glib-component
        v-show="showRefreshPrompt"
        :spec="warningSpec"
      />

      <glib-component
        v-if="historyFooterSpec"
        :spec="historyFooterSpec"
      />
      <glib-component
        v-if="!readOnly"
        ref="snapshotForm"
        :spec="liveFooterSpec"
      />
    </div>
  </div>
</template>

<script>
import chartRendering from "./mixins/chartRendering.js";
// import HillChartLocate from './_hillChartLocate.vue';
import footer from '@/templates/hillCharts/shared/_footer.js'

export default {
  mixins: [chartRendering],
  // components: {
  //   "epics-hillChartLocate": HillChartLocate,
  // },
  props: {
    spec: { type: Object, required: true }
  },
  data() {
    return {
      dataPoints: [],
      hillPoints: [],
      pointMap: {},
      broughtToFrontKey: null,
      isDirty: false,
      showRefreshPrompt: false,
      readOnly: true,
      heading: "",
      historyFooterSpec: null
    }
  },
  computed: {
    dirtyIconSpec() {
      return {
        // "view": "icon",
        "view": "button",
        "styleClasses": ["icon", "text", "x-small"],
        "icon": {
          "color": $tpu.themes.light.warning,
          "material": {
            "name": "warning",
          },
        },
        "onMouseEnter": {
          action: "popovers/open",
          key: "dirty_tooltip",
          placement: "top",
          childViews: [
            {
              "view": "p",
              "styleClasses": ["tooltip"],
              "text": "Unsaved!"
            }
          ]
        },
        "onMouseLeave": {
          action: "popovers/close",
          key: "dirty_tooltip",
        },
        "onClick": {
          action: "panels/scrollTo",
          viewId: "snapshot_form",
          placement: "bottom"
        }
      }
    },
    warningSpec() {
      return {
        "view": "banners/select",
        "width": "matchParent",
        "message": "Chart updated by other collaborators. Please refresh.",
        "styleClasses": [
          "warning"
        ],
        "buttons": [
          {
            "icon": {
              "material": {
                "name": "refresh"
              }
            },
            "onClick": {
              "action": "windows/reload",
            },
            "type": "button"
          }
        ]
      }
    },
    liveFooterSpec() {
      return {
        "view": "panels/split",
        "width": "matchParent",
        "height": "matchParent",
        "center": {
          "height": "matchParent",
          "childViews": [
            footer.snapshotLinkSpec(this.spec.projectId, this.spec.snapshotCount)
          ]
        },
        "right": {
          "childViews": [
            this.snapshotFormSpec
          ]
        }
      }
    },
    snapshotFormSpec() {
      return {
        "view": "panels/form",
        "padding": { top: 8 },
        "childViews": [
          {
            "view": "panels/horizontal",
            "id": "snapshot_form",
            "childViews": [
              {
                "view": "fields/hidden",
                "name": "snapshot[project_id]",
                "value": this.spec.projectId
              },
              {
                "view": "fields/text",
                "id": "snapshot_description",
                "width": 320,
                "maxLength": 100,
                "styleClasses": ["outlined", "compact", "small"],
                "name": "snapshot[description]",
                "label": "Snapshot description",
                "readOnly": !this.isDirty,
                "placeholder": "Max 100 chars"
                // "value": this.snapshotDescription
              },
              {
                "view": "spacer",
                "width": "8",
              },
              {
                "view": "fields/submit",
                "styleClasses": ["warning", "compact"],
                "height": 30,
                "text": "Save",
                "disabled": !this.isDirty
              }
            ]
          }
        ],
        "onSubmit": {
          "action": "commands/custom",
          "name": "snapshots/create"
        },
      }
    }
  },
  methods: {
    $mounted() {
      this.$onEvent("scopes/bringToFront", e => {
        this.bringToFront(e.data.key)
      });
    },
    $ready() {
      this.dataPoints = this.spec.points || []
      this.isDirty = false
      this.showRefreshPrompt = false
      this.shouldRefresh = false

      this.pointMap = this.dataPoints.reduce(function(map, obj) {
        map[obj.key] = obj;
        return map;
      }, {});

      this.initChartProperties(this.spec.fullSize)
      this.clearChart()
      const svg = this.generateChartLines()
      this.plotChartPoints(this.dataPoints, svg)

      this.readOnly = GLib.type.isNotNull(this.spec.snapshotIndex)
    },
    action_merge(mergedSpec) {
      Object.assign(this.spec, mergedSpec);
    },
    displaySnapshot(snapshot) {
      const createdDate = snapshot.createdDate;
      this.heading = `Snapshot #${snapshot.index + 1} (${createdDate})`

      Object.assign(this.spec, {
        points: snapshot.points
      });

      this.historyFooterSpec = footer.historySpec(
        `Snapshotted by ${snapshot.authorName}`,
        snapshot
      )
      this.$ready()
      // this.$recursiveUpdate();
    },
    displayLive(dataPoints) {
      if (!this.pointsChanged(dataPoints)) {
        return
      }

      if (this.isDirty) {
        this.showRefreshPrompt = true
        return
      }

      if (this.spec.fullSize) {
        this.heading = "How are we travelling?"
      }

      // this.shouldRefresh = true
      Object.assign(this.spec, {
        points: dataPoints
      });

      this.$ready()
    },
    pointsChanged(newPoints) {
      if (newPoints.length != Object.keys(this.pointMap).length) {
        return true
      }

      let changed = false
      newPoints.forEach((newPoint) => {
        const oldPoint = this.pointMap[newPoint.key]
        if (newPoint.x != oldPoint.x || !newPoint.title.equals(oldPoint.title)) {
          changed = true
          return
        }
      })
      return changed
    },
    clearDirtyState() {
      this.isDirty = false;
      this.$refs.snapshotForm.$recursiveUpdate()
    },
    locateButton() {
      return {
        "view": "button",
        "icon": { material: { name: $tpu.keys.icnSearch() } },
        "text": "Locate scope",
        "styleClasses": ["small", "tonal"],
        "onClick": {
          action: "popovers/open",
          placement: "right-start",
          styleClasses: ['popover-menu'],
          width: 300,
          childViews: this.dataPoints.map((point, index) => {
            return {
              "view": "label",
              "styleClasses": ["popover-menu-item"],
              "text": `${ point.title } - ${ Math.round(point.x) }%`,
              "onClick": {
                "action": "commands/custom",
                "name": "scopes/focus",
                "scopeCardId": point.key
              }
            }
          })
        }
      }
    }
  }
}


</script>

<style lang="scss">
.action-container {
  // margin-left: -100px;
  margin-left: -140px;
  display: flex;
}

.dirty-icon {
  margin-left: -40px;
  // margin-left: -140px;
  margin-right: 10px;
}

#hill-chart-heading {
  // margin-left: -140px;
  // width: 800px;
}

.hill-chart .axis path {
  stroke: #999;
}

.hill-chart .line {
  fill: none;
  // stroke: steelblue;
  stroke: #2b95ac;
  stroke-width: 1;
}

.hill-chart .middle {
  fill: none;
  stroke: #aaa;
  stroke-width: 1;
  shape-rendering: crispedges;
  stroke-dasharray: 5, 5;
}

.hill-chart .caption {
  fill: #888;
  font-style: italic;
  font-size: 0.7em;
  text-anchor: middle;
}

// #editable-hill .group {
//   cursor: move;
// }
.hill-chart.editable .group {
  cursor: move;
}

.hill-chart .group > circle {
  stroke: #fff;
  stroke-width: 2px;
}

.hill-chart .group > text {
  font-size: 0.75em;
  text-anchor: start;
  paint-order: stroke;
  stroke: #ffffff;
  stroke-width: 2px;
}

.horizontal {
  display: flex;
  flex-direction: row;
}
</style>
