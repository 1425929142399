<template>
  <div :data-reloadOnChange="lastUpdatedAt">
    <glib-component
      ref="scopeContainer"
      v-if="content"
      :spec="content"
    />
  </div>
</template>

<script>
import cycle from "./mixins/cycleManagement.js";
import scopeColumn from '@/templates/projects/_scopeColumn.js'
import { NotInitializedError } from '../../exceptions.js'

export default {
  mixins: [cycle],
  props: ["spec", "index"],
  data() {
    return {
      content: null,
      project: null,
      lastUpdatedAt: null,
      timer: null,
    }
  },
  methods: {
    $ready() {
      // No point refreshing UI if the card cache is stale. This can be important for frontend driven operation such
      // as moving cards, because sometimes the View shows a state that is not consistent with the Model, in which
      // cache we want to be able to properly refresh the UI.
      $tpu.r.projects.clearCache()
      $tpu.r.scopes.clearCache()
      $tpu.r.tasks.clearCache()

      this.action_invalidateList()

      // // Force refresh onReload.
      // this.lastUpdatedAt = null

      const t = TrelloPowerUp.iframe()

      const projectId = this.spec.project.id

      this.initContent(projectId, t)

      // This is for receiving external updates. We should never rely on this to reflect local updates because it is
      // not quick enough.
      this.timer = setInterval(() => {
        // TODO: Implement a check whereby if the lastUpdate was too close, just skip the current loop
        //       to avoid multiple refreshes coming from the same update. Not sure if this should be checked
        //       here or a bit further inside.
        //       The sloweness is evident when adding a checklist item and then quickly typing in the next
        //       item to be added, the typing will get laggy. This lagginess doesn't happend when adding a
        //       new task because there is no update happening when typing, whereas with checklist item
        //       the user might already started typing while this page is being refreshed.
        //       The slowness is also observed when selecting multiple card members in quick succession.
        if (!$tpu.vueApp.draggedComponent) {
          $tpu.lock.acquire($tpu.keys.lckCardMove(), async () => {
            await this.fetchData(projectId, t)
            // Accessory fetching needs to be after card fetching because it relies on the card's lastActivity.
            await this.fetchAccessory(t)
            this.action_updateList()
          }, (err, ret) => {
            // console.debug("Interval update lock released", err, ret)
          })
        }
      }, 1000);

      // $tpu.r.data.getMemberBoardData(t).then((data) => {
      //   data.projectId = projectId
      //   $tpu.r.data.setMemberBoardData(t, data)
      // })

      // $tpu.r.data.updateMemberBoardData(t, { projectId: projectId })

      $tpu.r.data.freshUpdateMemberBoardData(t, (data) => {
        data.projectId = projectId
      })
    },
    // This will also cache data that can be used in nested pages (e.g. card dialog).
    async fetchData(projectId, t) {
      const [projects, _cards] = await Promise.all([
        $tpu.r.projects.registry(t),
        $tpu.r.cards.registry(t)
      ])

      // Make sure to reference the cached object (not a separate object) so we can quickly
      // receive new updates.
      this.project = Object.assign(projects[projectId], this.spec.project)
      // this.project = $tpu.r.projects.find(projectId, this.spec.project)
    },
    async fetchAccessory(t) {
      await Promise.all([
        $tpu.r.lists.registry(t),
        $tpu.r.checklists.registry(t)
      ])
    },
    async initContent(projectId, t) {
      await Promise.all([
        this.fetchData(projectId, t),
        $tpu.urls.preloadBoard(t),
        $tpu.r.data.preloadOauthToken(t)
      ])

      // No need to wait. Let the card accessory load asynchronously.
      this.fetchAccessory(t)

      this.populateList(this.project, t)
      this.populateBreadcrumbs(this.project, t)

      // Execute this after the above promise awaits to allow time for component initialization.
      this.populateCycleComponents(t)

      // tour.startTourForPage2(this, this.project, t)
    },
    $tearDown() {
      if (this.timer != null) {
        clearInterval(this.timer);
        console.debug(`Timer stopped: ${this.timer}`);
        this.timer == null;
      }
    },
    action_invalidateList() {
      // Force refresh onReload.
      this.lastUpdatedAt = null
    },
    action_updateList() {
      const t = TrelloPowerUp.iframe()

      if (this.populateList(this.project, t)) {
        // Dragging is the only action where the frontend is updated before the backend, so
        // to avoid flickers caused by pre-backend-update rendering, we should hold off the
        // frontend update until the dragging is completed.
        if (!$tpu.vueApp.draggedComponent) {
          // Need to explicitly update the component because populateList() may get
          // called on an existing (including reused) component, e.g.
          // - Regular interval polling
          // - User action such as reordering a scope
          GLib.component.preserveScroll(this.$refs.scopeContainer.$recursiveUpdate())
        }
      }
    },
    populateList(project, t) {
      let scopeCache = null
      try {
        scopeCache = $tpu.r.scopes.cache(project, t, true)
      } catch (e) {
        if (e instanceof NotInitializedError) {
          // Card cache has not been loaded yet. This is possible because fetchAccessory() is
          // called later.
        } else {
          throw e
        }
      }

      if (!scopeCache) {
        return false
      }

      let lastUpdatedAt = scopeCache.lastRefreshedAt

      // Don't refresh if none of the cards or the current project have changed. Other entities such as lists
      // may have changed, but they are not as important and do not change that frequently.
      if (this.lastUpdatedAt && lastUpdatedAt <= this.lastUpdatedAt) {
        return false
      }

      console.debug("Populating project content...")
      this.lastUpdatedAt = lastUpdatedAt

      const scopeCards = scopeCache.data

      if (this.content) {
        // Subsequent refresh, focus on rendering the swimlanes first because likely this is what the user is interacting with.
        // Also because the swim lanes UI are heavier.
        this.populateSwimlanes(scopeCards, project)
        this.populateHillChart(scopeCards)
      } else {
        // First-time initialization, focus on rendering the hill chart first because this is the first thing that users see
        this.populateHillChart(scopeCards)
        setTimeout(() => {
          this.populateSwimlanes(scopeCards, project)
        })
      }

      return true
    },
    populateSwimlanes(scopeCards, project) {
      const columnSpecs = scopeCards.map((scopeCard) => {
        const taskCards = scopeCard.transient.taskCards
        return scopeColumn.spec(project, scopeCard, taskCards)
      })

      this.content = {
        "view": "panels/horizontal",
        // "id": "scope_container",
        "distribution": "space-5",
        "childViews": [
          {
            "view": "panels/vertical",
            "padding": { top: 22 },
            "childViews": [
              $tpu.editing.newScopeShowStatus ? this.newScopeColumnSpec(project, 0, null) : this.newScopeButton()
            ]
          },
          this.aggregateSpec(project, columnSpecs),
          {
            "view": "panels/vertical",
            "padding": { top: 22 },
            "childViews": [
              this.newScopeColumnSpec(project, null, "scope_name_append")
            ]
          }
        ],
      }
    },
    newScopeButton() {
      return {
        "view": "button",
        "icon": { "material": { "name": $tpu.keys.icnAddScope() } },
        "styleClasses": ["tonal", "small"],
        "onClick": {
          "action": "commands/custom",
          "name": "scopes/new",
          "properties": {
            newScopeShowStatus: true
          }
        }
      }
    },
    populateHillChart(scopeCards) {
      const dataPoints = scopeCards.map((card, index) => {
        return {
          key: card.id,
          title: card.name,
          x: card.shapeUp.hillPointX
        }
      })

      const hillChart = GLib.component.findById('hill_chart')
      hillChart.displayLive(dataPoints)
    },
    populateBreadcrumbs(project, t) {
      const nameCrumb = GLib.component.findById('crumb_project_name')
      nameCrumb.action_merge({
        text: project.name.truncate(30)
      })

      const projects = this.spec.projects
      const actionCrumb = GLib.component.findById('crumb_action')
      actionCrumb.action_merge({
        onClick: {
          action: "popovers/open",
          styleClasses: ['popover-menu'],
          placement: "bottom-start",
          width: 300,
          childViews: projects.map((project) => {
            return {
              "view": "label",
              "styleClasses": ["popover-menu-item"],
              "text": project.name,
              "onClick": {
                "action": "windows/open",
                "url": $tpu.urls.project(project.id)
              }
            }
          })
        }
      })
    },
    aggregateSpec(project, columnSpecs) {
      return {
        "view": "panels/horizontal",
        "distribution": "space-5",
        "childViews": columnSpecs,
        "dragSupport": {
          "groupId": "project",
          "onDrop": {
            "action": "commands/custom",
            "name": "scopes/move",
            "projectId": project.id
          },
          "paramNameForItemId": "scopeId",
          "paramNameForNewIndex": "newIndex"
        }
      }
    },
    newScopeColumnSpec(project, index, elementId) {
      return {
        "view": "panels/form",
        "width": "260",
        "padding": { top: 10, left: 10, right: 10, bottom: 6 },
        "backgroundColor": "#ebecf0",
        "styleClasses": ["card"],
        "autoFocus": true,
        "childViews": [
          {
            "view": "fields/text",
            "styleClasses": ["outlined", "compact"],
            "width": "matchParent",
            "name": "name",
            "label": "Add a scope",
            "placeholder": "Scope name",
            "id": elementId
          }
        ],
        "onSubmit": {
          "action": "commands/custom",
          "name": "scopes/create",
          "properties": {
            "projectId": project.id,
            "index": index,
          },
          "onSuccess": elementId ? {
            "action": "fields/reset",
            "targetId": elementId,
            "onReset": {
              "action": "fields/focus",
              "targetId": elementId,
            }
          } : null
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
