<template>
  <div :data-updatekey="spec.cardId">
    <glib-component
      ref="scopeContainer"
      v-if="content"
      :spec="content"
    />
  </div>
</template>

<script>
import contentColumn from './_contentColumn.js'
import actionColumn from './_actionColumn.js'

export default {
  props: ["spec", "index"],
  data() {
    return {
      content: null,
      cardLastUpdatedAt: null,
      timer: null
    }
  },
  methods: {
    $ready() {
      this.populateContent()

      this.timer = setInterval(() => {
        $tpu.lock.acquire($tpu.keys.lckCardMove(), async () => {
          await this.action_updateContent()
        }, (err, ret) => {
          // console.debug("Interval card refresh lock released", err, ret)
        })
      }, 1000);

      GLib.action.execute({
        action: "http/post",
        url: $tpu.urls.web_requests(),
        formData: {
          web_request: {
            controller_name: "cards",
            action_name: "show"
          },
          board_id: $tpu.urls.boardId
        },
        silent: true
      }, this);
    },
    $tearDown() {
      if (this.timer != null) {
        clearInterval(this.timer);
        console.debug(`Timer stopped: ${this.timer}`);
        this.timer == null;
      }
    },
    action_updateContent(force) {
      if (this.populateContent(force)) {
        this.$refs.scopeContainer.$recursiveUpdate();
      }
    },
    populateContent(force) {
      const card = $tpu.r.cards.find(this.spec.cardId)
      card.shapeUp.list = $tpu.r.lists.find(card.idList)
      const lastUpdatedAt = card.shapeUp.updatedAt

      // Use force only for switching to/from editing mode. Don't rely on it for data update in which case we
      // should rely on updatedAt instead -- but this is not yet the case, see base_action#_refreshPage()
      if (!force && this.cardLastUpdatedAt && lastUpdatedAt <= this.cardLastUpdatedAt) {
        return false
      }

      console.debug("Populating card content...")

      this.cardLastUpdatedAt = lastUpdatedAt
      this.content = this.contentSpec(card)
      return true
    },
    contentSpec(card) {
      return {
        "view": "panels/responsive",
        "childViews": [
          {
            "view": "panels/column-v1",
            "xs": {
              "cols": 8
            },
            "childViews": contentColumn.specs(card)
          },
          {
            "view": "panels/column-v1",
            "xs": {
              "cols": 1
            }
          },
          {
            "view": "panels/column-v1",
            "xs": {
              "cols": 3
            },
            "childViews": actionColumn.specs(card)
          }
        ]
      }
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
