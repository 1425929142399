<template>
  <!-- Bind anything to get Vue to watch `spec` -->
  <!-- <div :data-update-on-reload="spec.statusFilter"> -->

  <!-- Bind a random attribute to something that changes when `spec` is updated. -->
  <div :data-reloadOnChange="lastUpdatedAt">
    <glib-component
      ref="container"
      v-if="content"
      :spec="content"
    />
  </div>
</template>

<script>
import cycle from "./mixins/cycleManagement.js";
import projectList from './_projectList.js'
import tour from "./_tour.js";
import { NotInitializedError } from '../../exceptions.js'

export default {
  mixins: [cycle],
  props: ["spec", "index"],
  data() {
    return {
      content: null,
      lastUpdatedAt: null,
      timer: null,
      cycleProvided: false
      // reloadKey: null
    }
  },
  methods: {
    $ready() {
      // Force refresh onReload. We cannot rely on lastUpdatedAt getting modified locally because
      // some operations go straight to the server.
      $tpu.r.projects.clearCache()
      this.lastUpdatedAt = null

      const t = TrelloPowerUp.iframe()
      this.initComponent(t)

      this.timer = setInterval(() => {
        $tpu.lock.acquire($tpu.keys.lckCardMove(), async () => {
          await Promise.all([
            this.fetchData(t),
            this.fetchAccessory(t)
          ])
          this.updateList(t)
        }, (err, ret) => {
          // console.debug("Interval update lock released", err, ret)
        })
      }, 1000);

      // $tpu.r.data.getMemberBoardData(t).then((data) => {
      //   data.projectId = null
      //   $tpu.r.data.setMemberBoardData(t, data)
      // })

      $tpu.r.data.freshUpdateMemberBoardData(t, (data) => {
        data.projectId = null
      })
    },
    $tearDown() {
      if (this.timer != null) {
        clearInterval(this.timer);
        console.debug(`Timer stopped: ${this.timer}`);
        this.timer == null;
      }
    },
    async initComponent(t) {
      await Promise.all([
        this.initContent(t),
        this.populateCycleComponents(t)
      ])

      const lastViewedProject = await $tpu.r.projects.lastViewed(t)

      // This requires data from the methods above.
      tour.startTourForPage1(this, lastViewedProject, this.cycleProvided)

      // const tourContent = tour.stepsForPage1(lastViewedProject, this.cycleProvided)
      // GLib.action.execute(tourContent, this);

      // tour.stopPreviousTour()
    },
    async fetchData(t) {
      await Promise.all([
        $tpu.r.projects.registry(t),
        // This is for creating a new Scope during Project creation.
        $tpu.r.lists.registry(t)
      ])
    },
    async fetchAccessory(t) {
      await Promise.all([
        $tpu.r.cards.registry(t)
      ])
    },
    async initContent(t) {
      await Promise.all([
        this.fetchData(t),
        $tpu.urls.preloadBoard(t),
        $tpu.r.data.preloadOauthToken(t),
      ])

      this.populateList(t)
    },
    populateList(t) {
      const projectCache = $tpu.r.projects.cache(this.spec.projects)
      let lastUpdatedAt = projectCache.lastRefreshedAt

      const projects = projectCache.data
      projects.forEach((project) => {
        project.transient = project.transient || {}

        try {
          const scopeCache = $tpu.r.scopes.cache(project, t, false)
          project.transient.scopeCards = scopeCache.data

          if (scopeCache.lastRefreshedAt > lastUpdatedAt) {
            lastUpdatedAt = scopeCache.lastRefreshedAt
          }
        } catch (e) {
          if (e instanceof NotInitializedError) {
            // Card cache has not been loaded yet. This is possible because fetchAccessory() is
            // called later.
          } else {
            throw e
          }
        }
      })

      if (this.lastUpdatedAt && lastUpdatedAt <= this.lastUpdatedAt) {
        return false
      }

      console.debug("Populating project list...")
      this.content = projectList.spec(projects)
      this.lastUpdatedAt = lastUpdatedAt

      return true
    },
    updateList(t) {
      if (this.populateList(t)) {
        this.$refs.container.$recursiveUpdate();
      }
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
