<template>
</template>

<script>

export default {
  props: ["spec", "index"],
  methods: {
    $ready() {
      $tpu.icons = this.spec.icons;
      $tpu.globals = this.spec.globals;
    },
  }
};
</script>

<style lang="scss" scoped>
</style>
