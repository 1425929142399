<template>
  <!-- Bind a random attribute to something that changes when `spec` is updated. -->
  <div :data-reloadOnChange="lastUpdatedAt">
    <glib-component
      ref="container"
      v-if="content"
      :spec="content"
    />
  </div>
</template>

<script>
import scopeColumn from '@/templates/projects/_scopeColumn.js'
import { NotInitializedError } from '../../exceptions.js'

export default {
  props: ["spec", "index"],
  data() {
    return {
      content: null,
      lastUpdatedAt: null,
      timer: null,
      // reloadKey: null
    }
  },
  methods: {
    $ready() {
      // Force refresh onReload. We cannot rely on lastUpdatedAt getting modified locally because
      // some operations go straight to the server.
      // $tpu.r.projects.clearCache()

      $tpu.r.tasks.clearCache()
      this.lastUpdatedAt = null

      const t = TrelloPowerUp.iframe()
      this.initContent(t)

      this.timer = setInterval(() => {
        $tpu.lock.acquire($tpu.keys.lckCardMove(), async () => {
          await Promise.all([
            this.fetchData(t),
            this.fetchAccessory(t)
          ])
          this.action_updateList()
        }, (err, ret) => {
          // console.debug("Interval update lock released", err, ret)
        })
      }, 1000);
    },
    $tearDown() {
      if (this.timer != null) {
        clearInterval(this.timer);
        console.debug(`Timer stopped: ${this.timer}`);
        this.timer == null;
      }
    },
    // async initComponent(t) {
    //   await Promise.all([
    //     this.initContent(t),
    //     // this.populateCycleComponents(t)
    //   ])
    // },
    async fetchData(t) {
      await Promise.all([
        $tpu.r.cards.registry(t)
      ])
    },
    async fetchAccessory(t) {
      // TODO: Revise
      await Promise.all([
        $tpu.r.lists.registry(t),
        $tpu.r.checklists.registry(t)
      ])
    },
    async initContent(t) {
      await Promise.all([
        this.fetchData(t),
        $tpu.urls.preloadBoard(t),
        $tpu.r.data.preloadOauthToken(t),
      ])

      this.populateList(t)
    },
    populateList(t) {
      let lastUpdatedAt = 0
      let taskCache, scopeCard = null
      try {
        const frozenCache = $tpu.r.cards.cache()
        scopeCard = frozenCache[this.spec.scopeCardId]
        lastUpdatedAt = scopeCard.shapeUp.updatedAt

        taskCache = $tpu.r.tasks.cache(scopeCard, frozenCache, t)

        if (taskCache.lastRefreshedAt > lastUpdatedAt) {
          lastUpdatedAt = taskCache.lastRefreshedAt
        }

        if (!taskCache.accessoryComplete) {
          lastUpdatedAt = 0 // Keep refreshing until complete.
        }
      } catch (e) {
        if (e instanceof NotInitializedError) {
          // Card cache has not been loaded yet. This is possible because fetchAccessory() is
          // called later.
        } else {
          throw e
        }
      }

      if (!taskCache || !scopeCard) {
        return false
      }

      if (this.lastUpdatedAt && lastUpdatedAt <= this.lastUpdatedAt) {
        return false
      }

      console.debug("Populating scope content...")

      const taskCards = taskCache.data
      this.content = this.contentSpec(scopeCard, taskCards)
      this.lastUpdatedAt = lastUpdatedAt

      const projectPage = GLib.component.findById("project_show_content");

      return true
    },
    action_updateList() {
      const t = TrelloPowerUp.iframe()

      if (this.populateList(t)) {
        this.$refs.container.$recursiveUpdate();
      }
    },
    contentSpec(scopeCard, taskCards) {
      return {
        "view": "panels/vertical",
        "width": "matchParent",
        // "id": "scope_container",
        // "distribution": "space-5",
        "childViews": [scopeColumn.spec(null, scopeCard, taskCards)],
        // "dragSupport": {
        //   "groupId": "project",
        //   "onDrop": {
        //     "action": "commands/custom",
        //     "name": "scopes/move",
        //     "projectId": project.id
        //   },
        //   "paramNameForItemId": "scopeId",
        //   "paramNameForNewIndex": "newIndex"
        // }
      }
    },
  }
};
</script>

<style lang="scss" scoped>

</style>
