<template>
  <div>
  </div>
</template>

<script>
import scopes from '@/hooks/scopes.js'
import tasks from '@/hooks/tasks.js'
import cards from '@/hooks/cards.js'
import auth from '@/actions/authorizations/index.js'

export default {
  props: ["spec", "index"],
  data() {
    return {
      projectCorePromise: null,
      projectCores: null,
      autoLaunchChecked: false,
      boardId: null
    }
  },
  methods: {
    $ready() {
      TrelloPowerUp.initialize({
        "on-enable": this.onEnable,
        "board-buttons": this.boardButtons,
        // "card-buttons": this.cardButtons,
        "card-badges": this.cardBadges,
        "card-detail-badges": this.cardDetailBadges,
        "card-back-section": this.cardBackSection,
        // "authorization-status": this.authorizationStatus
      })

      // this.initPowerUp()
    },
    onEnable(t) {
      console.debug("Installing Power-Up...")

      const board = $tpu.urls.preloadBoard(t)
      this.autoLaunchChecked = true
      auth.conditionalLaunch(t, board, true)

      // This is for subsequent page load. Don't rely on this for the first page load because there is no
      // guarantee that the saved data will be available immediately in `boardButtons()`.
      $tpu.r.data.freshUpdateMemberBoardData(t, (data) => {
        data.isInstaller = true
      })
    },
    async boardButtons(t) {
      auth.resetData(t)

      const [board, projects, settings, token] = await Promise.all([
        $tpu.urls.preloadBoard(t),
        this.fetchProjectCores(t),
        $tpu.r.data.getMemberBoardData(t),
        $tpu.r.data.preloadOauthToken(t)
      ])

      // `projects` will be null if the user doesn't have permissions. See `hooks/scopes`
      let darkIcon, lightIcon
      if (projects && Object.keys(projects).length > 0) {
        darkIcon = $tpu.icons.shapeupWhite
        lightIcon = $tpu.icons.shapeupBlack
      } else {
        darkIcon = $tpu.icons.shapeupAlert
        lightIcon = $tpu.icons.shapeupAlert
      }

      if (this.boardId != board.id) { // User switched board
        this.autoLaunchChecked = false
        this.boardId = board.id
      }

      let autoLaunch = localStorage.getItem($tpu.keys.lclBoardAutoLaunch(board.id)) || ""

      if (settings.oneOffLaunch) {
        this.autoLaunchChecked = false
        autoLaunch = settings.oneOffLaunch

        $tpu.r.data.freshUpdateMemberBoardData(t, (data) => {
          data.oneOffLaunch = null
        })
      }
      // console.debug("Checking auto-launch...", autoLaunch, this.autoLaunchChecked, settings.isInstaller)

      // Except for the user who installed the Power-Up, don't show if the user hasn't authorized because
      // in a big team, not everyone will want to use the Shape Up dashboard.
      if (!this.autoLaunchChecked && !autoLaunch.equals("disabled") && (token || settings.isInstaller)) {
        auth.conditionalLaunch(t, board, autoLaunch.equals("large"))
      }
      this.autoLaunchChecked = true

      const prefix = GLib.settings.isDev ? ' (dev)' : ''
      return [{
        text: `Shape Up${prefix}`,
        icon: {
          dark: darkIcon,
          light: lightIcon,
        },
        // Be strict here because we don't want non-members to be able to use the feature.
        condition: 'edit',
        callback: (t) => {
          return auth.conditionalLaunch(t, board, true)
        }
      }]
    },
    async _fetchProjectCores(t) {
      await $tpu.r.data.preloadOauthToken(t)
      await $tpu.urls.preloadBoard(t)

      const result = await GLib.action.execute({
        action: "http/get",
        url: $tpu.urls.projects(),
        formData: { view: 'data_only' },
        silent: true
      }, this)

      let projectCores = null
      if (result.response.ok) {
        const projects = result.page
        projectCores = projects.reduce((acc, project) => {
          acc[project.id] = project
          return acc
        }, {});

        this.projectCores = projectCores
      }

      // Let the promise be reused by other requests that were made within X seconds.
      setTimeout(() => {
        this.projectCorePromise = null
      }, 5000)

      return projectCores
    },
    fetchProjectCores(t) {
      if (this.projectCorePromise) {
        return this.projectCorePromise
      }
      this.projectCorePromise = this._fetchProjectCores(t)
      return this.projectCorePromise
    },
    async _fetchData(t) {
      // Preload cache
      const [projectCores, projectExtras, cardRegistry, cardId] = await Promise.all([
        this.fetchProjectCores(t),
        $tpu.r.projects.registry(t),
        $tpu.r.cards.registry(t),
        $tpu.r.cards.currentId(t)
      ])

      // `currentCard` will be null if a card is archived or deleted.
      const currentCard = cardRegistry[cardId]

      let project, scopeCard = null
      // `projectCores` may be null for a few reasons:
      // - The board is visible to the user (e.g. visible to users in the same organization) but
      //   the user is not a member of the board.
      // - The user has not performed authorization.
      // - Temporary server issue. Because of this, it is better to stay silent.
      if (projectCores && currentCard) {
        // `project != null` means that this is a legit Scope card.
        project = projectCores[currentCard.shapeUp.projectId]
        // `scopeCard != null` means that this is a Task card and its Scope is not deleted.
        scopeCard = cardRegistry[currentCard.shapeUp.scopeCardId]
      }
      return {
        projectCores: projectCores, projectExtras: projectExtras, cardRegistry: cardRegistry,
        currentCard: currentCard, project: project, scopeCard: scopeCard
      }
    },
    cardBadges(t, opts) {
      return [
        {
          dynamic: async () => {
            const data = await this._fetchData(t)

            // A card created from the Shape Up board, will not be available in Trello board right away even though
            // it saves the card data in the local cache. This is because the Shape Up board runs in an iframe.
            if (!data.currentCard) {
              return cards.badge()
            }

            if (data.project) {
              return scopes.parentBadge(data.project)
            } else if (data.scopeCard) {
              return tasks.parentBadge(data.scopeCard)
            }
            return cards.badge()
          }
        },
        {
          dynamic: async () => {
            const data = await this._fetchData(t)

            if (!data.currentCard) {
              return cards.badge()
            }

            if (data.project) {
              return scopes.childBadge(data.currentCard)
            }
            return cards.badge()
          }
        }
      ]
    },
    async cardDetailBadges(t, opts) {
      return [
        {
          dynamic: async () => {
            const data = await this._fetchData(t)

            if (!data.currentCard) {
              return cards.badge()
            }

            if (data.project) {
              return scopes.typeDetailBadge(
                data.currentCard, data.projectCores, data.project
              )
            } else if (data.scopeCard) {
              return tasks.typeDetailBadge(
                data.currentCard, data.projectCores, data.cardRegistry, data.scopeCard
              )
            }
            return cards.badge()
          }
        },
        {
          dynamic: async () => {
            // const data = await this._fetchData(t)
            const [data, board] = await  Promise.all([
              this._fetchData(t),
              $tpu.urls.preloadBoard(t)
            ])

            if (!data.currentCard) {
              return cards.badge()
            }

            if (data.project) { // Scope card
              return scopes.parentDetailBadge(data.project, board)
            } else if (data.scopeCard) {
              return tasks.parentDetailBadge(
                data.currentCard, data.projectCores, data.projectExtras, data.cardRegistry, data.scopeCard)
            }
            return cards.attachParentDetailBadge(data.currentCard, data.projectCores, data.cardRegistry, board)
          }
        },
      ]
    },
    // cardButtons(t) {
    //   return $tpu.controller.cardButtons(t)
    // },
    async cardBackSection(t) {
      // const [cardRegistry, cardId] = await Promise.all([
      //   $tpu.r.cards.registry(t),
      //   $tpu.r.cards.currentId(t)
      // ])

      // const card = cardRegistry[cardId]

      const data = await this._fetchData(t)

      if (!data.currentCard) {
        return null
      }

      if (data.project) {
        return scopes.cardBackSection(data.currentCard, data.project, t)
      }
      return null
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
