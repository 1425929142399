/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

// import logger from './logger.js'
// logger.disableLogger();

import { Vue, settings, vueApp } from 'glib-web/index.js'
// require("channels")
// settings.reactive = true

const reportError = (err) => {
  const params = {
    message: err.toString(),
    stack: err.stack,
    authenticity_token: Utils.dom.getCsrf()
  }

  const formData = new FormData();
  for (const [key, value] of Object.entries(params)) {
    formData.append(key, value);
  }

  fetch("/errors", {
    method: "POST",
    body: formData,
    headers: {
      'Accept': 'application/json'
    }
  })
}

// See https://stackoverflow.com/questions/52071212/how-to-implement-global-error-handling-in-vue
Vue.config.errorHandler = (error, vm, info) => {
  console.error("Vue error:", error);
  reportError(error)
}

settings.errorHandler = (error, message) => {
  console.error("GLib error:", message, error);
  reportError(error)
}

window.addEventListener("error", (event) => {
  const error = event.error

  // Not needed. The original exception log would have been displayed anyway.
  // console.error("Root error:", error);

  reportError(error)
})

settings.themes = {
  light: {
    primary: "#2b95ac",
    warning: "#fb8c00"
  }
};


///// Templates

import PowerupsIndexTemplate from './templates/powerups/index.vue'
Vue.component('template-powerups-index', PowerupsIndexTemplate)

import DataInitializerTemplate from './templates/data/initializer.vue'
Vue.component('template-data-initializer', DataInitializerTemplate)

import ProjectsIndexTemplate from './templates/projects/index.vue'
Vue.component('template-projects-index', ProjectsIndexTemplate)

import ProjectsShowTemplate from './templates/projects/show.vue'
Vue.component('template-projects-show', ProjectsShowTemplate)

import ProjectsHillChartTemplate from './templates/projects/hillChart.vue'
Vue.component('template-projects-hillChart', ProjectsHillChartTemplate)

import ScopesShowTemplate from './templates/scopes/show.vue'
Vue.component('template-scopes-show', ScopesShowTemplate)

import CardsShowTemplate from './templates/cards/show.vue'
Vue.component('template-cards-show', CardsShowTemplate)

/////

///// Actions

/// Authorizations

import AuthorizationsIndexAction from './actions/authorizations/index.js'
GLib.action.registerCustom("authorizations/index", AuthorizationsIndexAction)

import AuthorizationsNewAction from './actions/authorizations/new.js'
GLib.action.registerCustom("authorizations/new", AuthorizationsNewAction)

import AuthorizationsProcessTokenAction from './actions/authorizations/processToken.js'
GLib.action.registerCustom("authorizations/processToken", AuthorizationsProcessTokenAction)

import AuthorizationsCreateAction from './actions/authorizations/create.js'
GLib.action.registerCustom("authorizations/create", AuthorizationsCreateAction)

import AuthorizationsDestroyAction from './actions/authorizations/destroy.js'
GLib.action.registerCustom("authorizations/destroy", AuthorizationsDestroyAction)

/// Settings

import SettingsUpdateCycleAction from './actions/settings/updateCycle.js'
GLib.action.registerCustom("settings/updateCycle", SettingsUpdateCycleAction)

import SettingsEditAction from './actions/settings/edit.js'
GLib.action.registerCustom("settings/edit", SettingsEditAction)

import SettingsUpdateAction from './actions/settings/update.js'
GLib.action.registerCustom("settings/update", SettingsUpdateAction)

/// Tasks

import TasksCreateAction from './actions/tasks/create.js'
GLib.action.registerCustom("tasks/create", TasksCreateAction)

import TasksMoveAction from './actions/tasks/move.js'
GLib.action.registerCustom("tasks/move", TasksMoveAction)

/// Scopes

import ScopesMoveAction from './actions/scopes/move.js'
GLib.action.registerCustom("scopes/move", ScopesMoveAction)

import ScopesNewAction from './actions/scopes/new.js'
GLib.action.registerCustom("scopes/new", ScopesNewAction)

import ScopesCreateAction from './actions/scopes/create.js'
GLib.action.registerCustom("scopes/create", ScopesCreateAction)

import ScopesFocusAction from './actions/scopes/focus.js'
GLib.action.registerCustom("scopes/focus", ScopesFocusAction)

/// Cards

import CardsOpenAction from './actions/cards/open.js'
GLib.action.registerCustom("cards/open", CardsOpenAction)

import CardsEditAction from './actions/cards/edit.js'
GLib.action.registerCustom("cards/edit", CardsEditAction)

import CardsUpdateAction from './actions/cards/update.js'
GLib.action.registerCustom("cards/update", CardsUpdateAction)

import CardsDestroyAction from './actions/cards/destroy.js'
GLib.action.registerCustom("cards/destroy", CardsDestroyAction)

import CardsSetDataAction from './actions/cards/setData.js'
GLib.action.registerCustom("cards/setData", CardsSetDataAction)

import CardMembersUpdateAction from './actions/cardMembers/update.js'
GLib.action.registerCustom("cardMembers/update", CardMembersUpdateAction)

/// Checklists

import ChecklistsCreateAction from './actions/checklists/create.js'
GLib.action.registerCustom("checklists/create", ChecklistsCreateAction)

import ChecklistsUpdateAction from './actions/checklists/update.js'
GLib.action.registerCustom("checklists/update", ChecklistsUpdateAction)

import ChecklistsDestroyAction from './actions/checklists/destroy.js'
GLib.action.registerCustom("checklists/destroy", ChecklistsDestroyAction)

/// CheckItems

import CheckItemsCreateAction from './actions/checkItems/create.js'
GLib.action.registerCustom("checkItems/create", CheckItemsCreateAction)

import CheckItemsUpdateAction from './actions/checkItems/update.js'
GLib.action.registerCustom("checkItems/update", CheckItemsUpdateAction)

import CheckItemsDestroyAction from './actions/checkItems/destroy.js'
GLib.action.registerCustom("checkItems/destroy", CheckItemsDestroyAction)

/// Projects

import ProjectsFinalizeCreateAction from './actions/projects/finalizeCreate.js'
GLib.action.registerCustom("projects/finalizeCreate", ProjectsFinalizeCreateAction)

import ProjectsDestroyAction from './actions/projects/destroy.js'
GLib.action.registerCustom("projects/destroy", ProjectsDestroyAction)

import ProjectsIndexAction from './actions/projects/index.js'
GLib.action.registerCustom("projects/index", ProjectsIndexAction)

/// Snapshots

import SnapshotsCreateAction from './actions/snapshots/create.js'
GLib.action.registerCustom("snapshots/create", SnapshotsCreateAction)

import SnapshotsFinalizeCreateAction from './actions/snapshots/finalizeCreate.js'
GLib.action.registerCustom("snapshots/finalizeCreate", SnapshotsFinalizeCreateAction)

import SnapshotsShowAction from './actions/snapshots/show.js'
GLib.action.registerCustom("snapshots/show", SnapshotsShowAction)

/////

import keys from './keys.js'
import styles from './styles.js'
import urls from './urls.js'

import dataRepo from './repos/data.js'
import projectsRepo from './repos/projects.js'
import listsRepo from './repos/lists.js'
import cardsRepo from './repos/cards.js'
import checklistsRepo from './repos/checklists.js'
import checkItemsRepo from './repos/checkItems.js'
import cardMembersRepo from './repos/cardMembers.js'

import tasksDomain from './domains/tasks.js'
import scopesDomain from './domains/scopes.js'

import scopesHook from './hooks/scopes.js'
import tasksHook from './hooks/tasks.js'

// const AsyncLock = require('async-lock');
import AsyncLock from "async-lock";


window.$tpu = {
  vue: Vue,
  vueApp: vueApp,
  keys: keys,
  styles: styles,
  urls: urls,
  themes: settings.themes,
  editing: {
    titleCardId: null,
    descriptionCardId: null,
    checklistId: null,
    checkItemId: null,
    newItemChecklistId: null,
    newChecklistCardId: null,
    newScopeShowStatus: false
  },
  r: { // Repos
    // TODO: Data is not a domain, move it out
    data: dataRepo,
    projects: projectsRepo,
    lists: listsRepo,
    cards: cardsRepo,
    checklists: checklistsRepo,
    checkItems: checkItemsRepo,
    cardMembers: cardMembersRepo,

    scopes: scopesDomain,
    tasks: tasksDomain,
  },
  h: { // Hooks
    scopes: scopesHook,
    tasks: tasksHook,
  },
  u: { // Utilities
    errors: { report: reportError }
  },
  lock: new AsyncLock()
}

settings.headerAugmenter = $tpu.r.data.httpHeaders


